<!-- 工单设置 -->
<template>
  <div v-loading="loading" class="container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="任务" name="first">
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <el-button
          @click="
            () => {
              $router.push('/work-order/newadd');
            }
          "
          class="common-screen-btn"
          type="primary"
          >新增类型</el-button
        >
        <!-- 列表 -->
        <work-table
          ref="work"
          :tableData="WorkOrderList"
          :childPagination="pagination"
          :paramsPageNum="params.pageNum"
          :paramsPageSize="params.pageSize"
          :activeName="activeName"
          @updataList="updataList"
          @changes="changes(arguments)"
        ></work-table>
      </el-tab-pane>
      <el-tab-pane label="审批" name="second">
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <el-button
          @click="
            () => {
              $router.push('/work-order/new-examine');
            }
          "
          class="common-screen-btn"
          type="primary"
          >新增类型</el-button
        >
        <work-table
          ref="work"
          :tableData="WorkOrderList"
          :childPagination="pagination"
          :paramsPageNum="params.pageNum"
          :paramsPageSize="params.pageSize"
          :activeName="activeName"
          @updataList="updataList"
          @changes="changes(arguments)"
        ></work-table>
      </el-tab-pane>
      <el-tab-pane name="third">
        <div slot="label">
          <el-tooltip class="item" effect="dark" content="直接关联到系统内应用场景的审批" placement="bottom">
              <div style="display: flex;align-items: center;">
                <span>应用场景审批</span> 
                <img
                  src="~@/assets/images/question.png"
                  style="margin-left: 8px;"
                />
              </div>
          </el-tooltip>
        </div>
        <breadcrumb :BreadcrumbData="BreadcrumbData2"></breadcrumb>
        <el-button
          @click="
            () => {
              $router.push('/work-order/scene');
            }
          "
          class="common-screen-btn"
          type="primary"
          >新增类型</el-button
        >
        <work-table
          ref="work"
          :tableData="WorkOrderList"
          :childPagination="pagination"
          :paramsPageNum="params.pageNum"
          :paramsPageSize="params.pageSize"
          :activeName="activeName"
          @updataList="updataList"
          @changes="changes(arguments)"
        ></work-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../utils/index.js";
import Breadcrumb from "../../components/common/breadcrumb.vue";
import workTable from "./children/orderTable.vue";
import { workOrderType } from "../../service/workOrder.js";

export default {
  name: "settingPage",
  components: { Breadcrumb, workTable },
  props: {},
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "工单", isLink: false },
        { title: "工单设置", isLink: false }
      ],
      BreadcrumbData2: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "工单", isLink: false },
        { title: "工单设置", isLink: false },
        { title: "工单类型", isLink: false },
      ],
      loading: false,
      activeName: "first",
      params: {
        workTypeId: 1, //工单类型
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      WorkOrderList: [],
      rolesCompany: this.$sto.get(Config.constants.userInfo).company_id,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if(this.$route.query.num == 'sec'){
      this.activeName = "second"
      this.params.workTypeId = 2
    }else if(this.$route.query.num == 'third') {
      this.activeName = "third"
      this.params.workTypeId = 3
    }
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany;
      this.getWorkOrderList();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    handleClick(tab) {
      if (tab.name == "first") {
        this.$router.push('/work-order/setting')
        this.params.workTypeId = 1;
        this.getWorkOrderList();
      } else if(tab.name == "second"){
        this.params.workTypeId = 2;
        this.getWorkOrderList();
      } else {
        this.params.workTypeId = 3;
        this.getWorkOrderList();
      }
    },
    async getWorkOrderList() {
      this.loading = true;
      let params = { ...this.params };
      const { data } = await workOrderType(params);
      this.WorkOrderList = data.list;
      this.pagination.total = data.total;
      this.pagination.pageSizes = data.pageSize;
      this.loading = false;
    },
    updataList() {
      this.getWorkOrderList();
    },
    changes(val) {
      if (val[0] == "num") {
        this.params.pageNum = val[1];
      } else {
        this.params.pageSize = val[1];
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  text-align: left;
  /deep/.el-tabs__nav{
    height: 70px;
  }
  /deep/.el-tabs__item{
    height: 100%;
    padding-top: 15px !important; 
  }
  /deep/.el-tabs__content{
    padding: 0 16px 16px;
  }
  .breadcrumb-container{
    padding-left: 16px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .hebtns {
    }
    .common-screen-btn {
      padding: 0 20px !important;
    }
  }
}
</style>
